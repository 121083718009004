import { render, staticRenderFns } from "./Catalog.vue?vue&type=template&id=646ad9c5&scoped=true&"
import script from "./Catalog.vue?vue&type=script&lang=js&"
export * from "./Catalog.vue?vue&type=script&lang=js&"
import style0 from "./Catalog.vue?vue&type=style&index=0&id=646ad9c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "646ad9c5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VAlert,VBtn,VCard,VCardText,VCardTitle,VCol,VContainer,VDivider,VIcon,VImg,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VRow,VSubheader,VTextField,VTreeview})
