export default class Model {
  constructor() {
    this.requestBody = [];
    this.body = [];
  }
  setBody(data) {
    if (data) {
      this.body = [];
      let category;
      for (let i = 0; i < data.length; i++) {
        category = serializeCategory(data[i]);
        this.body.push(category);
      }
    }
    function serializeCategory(category) {
      if (category.children.length !== 0) {
        let serializedChildren = [];
        for (let i = 0; i < category.children.length; i++) {
          let serializedChild = serializeCategory(category.children[i]);
          serializedChildren.push(serializedChild);
        }
        category.children = serializedChildren;
      }
      return {
        id: category.id,
        parentId: category.parentId,
        name: category.name,
        orderOnPage: category.orderOnPage,
        children: category.children,
        image:
          category.categoryImages?.image.fileUrl || "@/assets/placeholder.png",
      };
    }
    return [
      {
        id: 0,
        parentId: 0,
        name: "Главная Родительская категория",
        orderOnPage: 0,
        children: this.body,
        image: "",
      },
    ];
  }
  serializeCategory(category) {
    if (category.children.length !== 0) {
      let serializedChildren = [];
      for (let i = 0; i < category.children.length; i++) {
        let serializedChild = this.serializeCategory(category.children[i]);
        serializedChildren.push(serializedChild);
      }
      category.children = serializedChildren;
    }
    return {
      id: category.id,
      parentId: category.parentId,
      name: category.name,
      orderOnPage: category.orderOnPage,
      children: category.children,
      image:
        category.categoryImages?.image.fileUrl || "@/assets/placeholder.png",
    };
  }
  createRequestBody(data) {
    if (data) {
      const parentId = data[0].parentId;
      this.requestBody = data.map((item, index) => {
        item.orderOnPage = index + 1;
        return {
          id: item.id,
          position: item.orderOnPage,
        };
      });
      const payload = {
        body: this.requestBody,
        parentCategoryId: parentId,
      };
      return payload;
    }
  }
}
