<template>
  <v-container fluid>
    <loader v-if="loading" />
    <v-card :dark="$dark.get()" class="mr-2">
      <v-card-title> {{ $route.name }} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="5">
            <v-subheader>
              <v-icon>mdi-label</v-icon>
              <v-text-field
                v-model="search"
                label="Выберите Категорию"
                clearable
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
            </v-subheader>
            <v-treeview
              :items="items"
              :search="search"
              :filter="filter"
              :active.sync="active"
              :open="[0]"
              dense
              activatable
              color="warning"
            >
              <template v-slot:append="{ item }">
                <v-btn v-if="item.id !== 0" icon :to="`category/${item.id}`">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-treeview>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col cols="6">
            <v-subheader>
              <v-icon>mdi-label</v-icon>
              <v-text-field
                readonly
                :value="'Переместите порядок'"
              ></v-text-field>
            </v-subheader>
            <draggable
              v-if="selectedCategoryChildren.length !== 0"
              v-model="selectedCategoryChildren"
              class="list-group"
              tag="ul"
              v-bind="dragOptions"
              activatable
              color="warning"
              open-on-click
              transition
              @start="drag = true"
              @end="
                drag = false;
                makeRequstChangeOrder();
              "
            >
              <transition-group
                type="transition"
                :name="!drag ? 'flip-list' : null"
              >
                <v-list-item
                  v-for="item in selectedCategoryChildren"
                  :key="item.id"
                  dense
                  class="list-item"
                >
                  <v-list-item-icon>
                    <v-img
                      width="25px"
                      height="25px"
                      class="rounded-pill"
                      :src="item.image"
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name" />
                  </v-list-item-content>
                </v-list-item>
              </transition-group>
            </draggable>
            <v-container v-else>
              <v-alert dense type="info">
                Выберите категорию, у которой есть свои подкатегории
              </v-alert>
            </v-container>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
import loader from "@/components/Loader";
import Model from "@/model/catalog.js";
export default {
  components: {
    draggable,
    loader,
  },
  data() {
    return {
      loading: false,
      drag: false,
      search: null,
      items: [], // Категории в древовидном виде
      model: {}, // модель которая сериализирует данные: setBody для treeview e.g.
      active: [], // Проп, контролирующий какие узлы активны. Массив состоит из ключа каждого активного элемент.
      additionalParentCategory: {
        id: 0,
        parentId: 0,
        name: "Главная Категория",
        orderOnPage: 0,
        children: [],
        image: "no",
      },
    };
  },
  computed: {
    ...mapGetters({
      CATALOG_CATEGORY_LIST: "Catalog/CATALOG_CATEGORY_LIST",
    }),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1;
    },
    // при выборе из treeview категории выводятся ее подкатегории во второй колонке
    selectedCategoryChildren: {
      get() {
        if (!this.active.length) return [];
        const id = this.active[0]; // здесь всегда хранится массив из тех выбранных v-list-item в v-treeview (в аттрибутах пока что стоит возможность выбора только одной категории)
        const exactCategory = this.findElementInTree(this.items, "id", id);
        const result = exactCategory.children;
        return result;
      },
      set(newValue) {
        let commonParentId = newValue[0].parentId;
        this.items = this.items.map((item) =>
          this.updatePropertyByIdInTree(
            item,
            commonParentId,
            "children",
            newValue
          )
        );
      },
    },
  },
  async created() {
    this.loading = true;
    await this.setBody();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      GET_ALL_CATEGORIES: "Catalog/GET_ALL_CATEGORIES",
      EDIT_POSITION: "Catalog/EDIT_POSITION",
    }),
    async setBody() {
      await this.GET_ALL_CATEGORIES();
      this.model = new Model();
      this.items = this.model.setBody(this.CATALOG_CATEGORY_LIST);
    },
    async makeRequstChangeOrder() {
      this.loading = true;
      const requestBody = this.model.createRequestBody(
        this.selectedCategoryChildren
      );
      await this.EDIT_POSITION(requestBody);
      this.loading = false;
    },
    findElementInTree(array, matchingTitle, identifier) {
      return array
        .map((category) => this.searchTree(category, matchingTitle, identifier))
        .filter((item) => item !== null)[0];
    },
    searchTree(element, property, indentifier) {
      if (element[property] == indentifier) {
        return element;
      } else if (element.children != null) {
        let result = null;
        for (let i = 0; result == null && i < element.children.length; i++) {
          result = this.searchTree(element.children[i], property, indentifier);
        }
        return result;
      }
      return null;
    },
    updatePropertyByIdInTree(data, id, property, value) {
      if (data.id == id) {
        data[property] = value;
      }
      if (data.children !== undefined && data.children.length > 0) {
        for (let i = 0; i < data.children.length; i++) {
          data.children[i] = this.updatePropertyByIdInTree(
            data.children[i],
            id,
            property,
            value
          );
        }
      }
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
.list-item {
  &:hover {
    cursor: pointer;
    background-color: antiquewhite;
    border-radius: 10px;
  }
}
</style>
